import { template as template_1ec8a44901b245fcbb84d8a3eb6b5aa7 } from "@ember/template-compiler";
const FKText = template_1ec8a44901b245fcbb84d8a3eb6b5aa7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
