import { template as template_4ada8fffd0574c25b2c4e0aead8c8365 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_4ada8fffd0574c25b2c4e0aead8c8365(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
